import styled from '@emotion/styled';
import { OverviewMap } from '@resistapp/client/components/map/overview-map';
import { OverviewFocusHeader } from '@resistapp/client/components/overview-focus-header/overview-focus-header';
import { ChartContainer } from '@resistapp/client/components/plots/trendchart/chart-container/chart-container';
import { ArgiChart } from '@resistapp/client/components/plots/trendchart/chart/chart-types/argi-chart';
import { ReductionChart } from '@resistapp/client/components/plots/trendchart/chart/chart-types/reduction-chart';
import { RiskScoreChart } from '@resistapp/client/components/plots/trendchart/chart/chart-types/risk-score-chart';
import { SiteDetailBoxes } from '@resistapp/client/components/selected-map-environment/site-detail-boxes';
import { SiteDetailContainer } from '@resistapp/client/components/selected-map-environment/site-details/general-site-details';
import { DeadCenter } from '@resistapp/client/components/shared/layout';
import { oldTheme } from '@resistapp/client/components/shared/old-styles';
import { theme } from '@resistapp/client/components/shared/theme';
import { LoadingView } from '@resistapp/client/components/states/loading';
import { useSampleDataContext } from '@resistapp/client/contexts/sample-data-context';
import { TutorialContextProvider } from '@resistapp/client/contexts/tutorial-context';
import { useUrlContext } from '@resistapp/client/contexts/url-provider';
import { useOverviewContext } from '@resistapp/client/contexts/use-overview-context/use-overview-context';
import { useUser } from '@resistapp/client/contexts/use-user-context';
import { AllProjectEnvironmentTypesGroup, ComparableEnvGroupType } from '@resistapp/common/comparable-env-groups';
import { Feature, hasFeature, hasMetricMode } from '@resistapp/common/features';
import { MetricMode } from '@resistapp/common/types';
import { publicProjects } from '@resistapp/common/utils';
import { useEffect, useMemo, useRef } from 'react';

export function OverviewView() {
  const {
    loading,
    error,
    data,
    trenchartTooltip: { setTrendChartSize, trendChartSize },
    metricMode,
  } = useOverviewContext();
  const { queryFilters } = useSampleDataContext();
  const { selectedEnvironmentTypeGroup } = useOverviewContext();
  const { user } = useUser();
  const { projectId, page } = useUrlContext();
  const oldCurrentRef = useRef<HTMLDivElement | null>();
  const containerRef = useRef<HTMLDivElement>(null);
  const current = containerRef.current;
  const isPublicProject = useMemo(
    () => Boolean(projectId.viable && publicProjects.includes(projectId.viable)),
    [projectId.viable],
  );

  const hasAccess = useMemo(() => {
    if (isPublicProject) {
      return (
        page.current === 'index' ||
        (hasFeature(user, Feature.RISK) && page.current === 'risk') ||
        (hasFeature(user, Feature.REDUCTION) && page.current === 'reduction')
      ); // Only allow ARGI for public projects
    }
    return (
      (hasFeature(user, Feature.ARGI) && page.current === 'index') ||
      (hasFeature(user, Feature.RISK) && page.current === 'risk') ||
      (hasFeature(user, Feature.REDUCTION) && page.current === 'reduction')
    );
  }, [user, page, isPublicProject]);

  useEffect(() => {
    if (containerRef.current && oldCurrentRef.current !== current) {
      const resizeObserver = new ResizeObserver((entries: ResizeObserverEntry[]) => {
        setTrendChartSize({
          width: entries[0].contentRect.width,
          height: entries[0].contentRect.height,
        });
      });

      resizeObserver.observe(containerRef.current);

      return () => {
        resizeObserver.disconnect();
      };
    }
    oldCurrentRef.current = current;
    return undefined;
  }, [current]);

  if (loading) {
    return <LoadingView />;
  } else if (error) {
    throw error;
  } else if (!data?.id) {
    return (
      <DeadCenter>
        <div>Please select projects</div>
      </DeadCenter>
    );
  } else if (!hasAccess) {
    return (
      <DeadCenter>
        <div>Subscription required</div>
      </DeadCenter>
    );
  }

  const noDataAvailableForSite =
    metricMode === MetricMode.REDUCTION &&
    selectedEnvironmentTypeGroup !== ComparableEnvGroupType.WATER_TREATMENT &&
    selectedEnvironmentTypeGroup !== AllProjectEnvironmentTypesGroup.ALL_PROJECT_ENVIRONMENTS;

  return (
    <TutorialContextProvider>
      <OverviewContainer>
        <OverviewMap />
        <DetailContainer>
          <OverviewFocusHeader />
          {noDataAvailableForSite ? (
            <SiteDetailContainer>
              <DeadCenter style={{ height: '60px' }}>No data available for this environment type</DeadCenter>
            </SiteDetailContainer>
          ) : (
            <SiteDetailBoxes />
          )}
          <ChartContainer>
            <div ref={containerRef} id="trendchart-ref-container">
              {metricMode === MetricMode.ARGI ? (
                <ArgiChart width={trendChartSize.width} height={trendChartSize.height} queryFilters={queryFilters} />
              ) : !hasMetricMode(user, metricMode) && isPublicProject ? (
                <DeadCenter>
                  <div>Subscription required for Risk Score and Reduction views</div>
                </DeadCenter>
              ) : metricMode === MetricMode.RISK ? (
                <RiskScoreChart width={trendChartSize.width} height={trendChartSize.height} />
              ) : (
                <ReductionChart width={trendChartSize.width} height={trendChartSize.height} />
              )}
            </div>
          </ChartContainer>
        </DetailContainer>
      </OverviewContainer>
    </TutorialContextProvider>
  );
}

const OverviewContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${theme.colors.neutral50};
  overflow: hidden;
`;

const DetailContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${oldTheme.spacing.l};
  padding: ${oldTheme.spacing.l};
  padding-bottom: 0;
`;
