import { theme } from '@resistapp/client/components/shared/theme';
import { useSampleDataContext } from '@resistapp/client/contexts/sample-data-context';
import { useOverviewContext } from '@resistapp/client/contexts/use-overview-context/use-overview-context';
import { getMetricAndLevel, getMetricColor } from '@resistapp/client/utils/metric-utils';
import { MetricMode } from '@resistapp/common/types';
import { LinearGradient } from '@visx/gradient';
import { isNil } from 'lodash';
import { positioning } from '../chart-styles';
import { getGradientScale } from '../scales';
import { BaseChart } from './base-chart';

interface RiskScoreChartProps {
  width: number;
  height: number;
}

export function RiskScoreChart(props: RiskScoreChartProps) {
  const { width, height } = props;
  const { queryFilters } = useSampleDataContext();
  const {
    trenchartTooltip: { TooltipComponentForRiskScore },
    processMode,
    activeOverviewConfiguration,
    activeChartUnit,
  } = useOverviewContext();

  const graphHeight = height - positioning.margin.top - positioning.margin.bottom;
  const indexScale = getGradientScale(graphHeight, [
    activeOverviewConfiguration.detailBarGraphMin,
    activeOverviewConfiguration.detailBarGraphMax(),
  ]);

  return (
    <BaseChart
      width={width}
      height={height}
      indexScale={indexScale}
      Legend={GradientLegend}
      getValue={d => {
        const [metric] = getMetricAndLevel(
          d,
          queryFilters.filters.selectedTargets,
          MetricMode.RISK,
          processMode,
          activeChartUnit,
        );
        return isNil(metric) ? undefined : indexScale(metric);
      }}
      TooltipComponent={TooltipComponentForRiskScore}
    />
  );
}

interface GradientLegendProps {
  left: number;
  height: number;
  width: number;
}

function GradientLegend({ left, height, width }: GradientLegendProps) {
  const cornerRadius = theme.borders.radius.small;
  const { activeChartUnit } = useOverviewContext();

  return (
    <svg width={width} height={height} style={{ position: 'absolute', left }}>
      <defs>
        <clipPath id="rounded-corner">
          <rect
            x={0}
            y={positioning.margin.top / 2}
            width={width}
            height={height - positioning.margin.top - positioning.margin.bottom}
            rx={cornerRadius}
          />
        </clipPath>
        <LinearGradient
          id="resistance-index-gradient"
          from={theme.colors.red200}
          to={theme.colors.red500}
          x1="0%"
          y1="100%"
          x2="0%"
          y2="0%"
        >
          <stop offset="0%" stopColor={getMetricColor(0, MetricMode.RISK, activeChartUnit)} />
          <stop offset="100%" stopColor={getMetricColor(100, MetricMode.RISK, activeChartUnit)} />
        </LinearGradient>
      </defs>
      <rect
        x={0}
        y={positioning.margin.top / 2}
        width={width}
        height={height - positioning.margin.top - positioning.margin.bottom}
        fill="url(#resistance-index-gradient)"
        clipPath="url(#rounded-corner)"
      />
    </svg>
  );
}
