import { OverviewContextData } from '@resistapp/client/contexts/use-overview-context/use-overview-context';
import { OverviewDatum } from '@resistapp/client/data-utils/plot-data/build-overview-line-data';
import { QueryFilters } from '@resistapp/client/hooks/use-query-filters/use-query-filters';
import { AdminArea, getAllOriginalEnvironmentNames } from '@resistapp/common/types';
import { isNil } from 'lodash';

// TODO: re-create wastpan projects now that we have dynamic pooling (see also src/common/utils.ts)
// export const wastpanDemoSubProjectByRegion: PartialDict<number> = {
//   Uusimaa: 1742,
// };

// export const globalDemoSubProjectByCountry: PartialDict<number> = {
//   Finland: 1673,
//   Indonesia: 1672,
//   Nepal: 1681,
//   Thailand: 1670,
// };

// export function getCountryIfDemoSubProject(projectId: number) {
//   return Object.entries(globalDemoSubProjectByCountry).find(([_, id]) => id === projectId)?.[0];
// }

export function onMarkerClick(
  closeClick: boolean,
  marker: Pick<OverviewDatum, 'environmentAfter' | 'environment' | 'inferredLat' | 'inferredLon'>,
  _navigate: (path: string, keepQuery: boolean) => void,
  toggleEnvironment: QueryFilters['toggleEnvironment'],
  adminLevelsActive: boolean,
  zoomAndCenter: OverviewContextData['zoomAndCenter'],
  changeZoomedAdminArea: OverviewContextData['changeZoomedAdminArea'],
  hideTooltip: () => void,
  previousAdminArea: AdminArea | undefined,
) {
  if (closeClick) {
    // If we select a project, the projectId exists, but if we unselect it is undefined
    toggleEnvironment(undefined, true);
    changeZoomedAdminArea(previousAdminArea || undefined, { previous: true });
    return;
  }
  // if (projectId === globalDemoProjectId) {
  //   const demoSubProjectId = globalDemoSubProjectByCountry[marker.environment.name];
  //   if (demoSubProjectId) {
  //     navigate(`/index/${demoSubProjectId}`, true);
  //   }
  //   } else if (projectId === wastpanDemoProjectId) {
  //     const demoSubProjectId = wastpanDemoSubProjectByRegion[marker.environment.name];
  //     if (demoSubProjectId) {
  //       navigate(`/index/${demoSubProjectId}`, true);
  //     } else {
  //       navigate(`/research/${projectId}`, true);
  //     }
  // } else
  if (!adminLevelsActive) {
    toggleEnvironment(getAllOriginalEnvironmentNames(marker.environment, marker.environmentAfter), true);
    if (!isNil(marker.inferredLon) && !isNil(marker.inferredLat)) {
      zoomAndCenter({ zoom: 13, center: [marker.inferredLon, marker.inferredLat] });
    }
    changeZoomedAdminArea(null);
    hideTooltip();
  }
}
