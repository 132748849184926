import styled from '@emotion/styled';
import { oldTheme } from '@resistapp/client/components/shared/old-styles';

interface MaybeApiErrorProps {
  error: Pick<Error, 'message'> | undefined | null;
  isAdmin?: boolean;
}

// Use this when you want to show an error to the user, that comes from network call
export function MaybeApiError({ error, isAdmin }: MaybeApiErrorProps) {
  if (!error) {
    return null;
  }
  const failedToFetchInfo = isAdmin
    ? 'Failed to reach or get a response from the server. If it took over a minute to see this message, the process is likely still ongoing...'
    : 'Failed to reach or get a response from the server.';
  const message = error.message === 'Failed to fetch' ? failedToFetchInfo : error.message || 'no message';

  if (message === 'An internal server error occurred') {
    return (
      <ErrorDiv>
        This project is temporarily unavailable due to a data structure update.
        <br />
        We apologize for the inconvenience. Please try again in an hour.
      </ErrorDiv>
    );
  }

  return <ErrorDiv>{message}</ErrorDiv>;
}

export const ErrorDiv = styled.div`
  margin-top: ${oldTheme.spacing.s};
  color: ${oldTheme.cautionRed};
`;
